/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum Color {
	COLOR_1 = "#9BC837",
	COLOR_2 = "#F8B876",
	COLOR_3 = "#F99393",
	COLOR_4 = "#30b6dd",
	COLOR_5 = "#FCC425",
	COLOR_6 = "#3F51B5",
	COLOR_7 = "#57CB56",
	COLOR_8 = "#2196F3",
	COLOR_9 = "#CED0DB",
	COLOR_10 = "#FCAA00",
	COLOR_11 = "#8A8C99",
	COLOR_12 = "#FA4B4B",
}

export {
	Color,
};
