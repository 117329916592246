/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/*
	Please, keep it sorted alphabetically in ascending order to make it easier to
	read.
 */
enum PROJECT_VIEW_MODE {
	EMPLOYEE_DAILY = "employee_daily",
	POSITION_BREAKDOWN = "position_breakdown",
	POSITION_BREAKDOWNS = "position_breakdowns",
	POSITION_DAILY = "position_daily",
	POSITION_STRUCTURED = "position_structured",
	POSITION_SUMMARY = "position_summary",
}

/*
	Please, keep it sorted alphabetically in ascending order to make it easier to
	read.
 */
const PROJECT_VIEW_MODE_TITLE: Readonly<Record<PROJECT_VIEW_MODE, string>> = {
	[PROJECT_VIEW_MODE.EMPLOYEE_DAILY]: "Employee daily",
	[PROJECT_VIEW_MODE.POSITION_BREAKDOWN]: "Position detailed",
	[PROJECT_VIEW_MODE.POSITION_BREAKDOWNS]: "Staffing desk breakdowns",
	[PROJECT_VIEW_MODE.POSITION_DAILY]: "Non structured",
	[PROJECT_VIEW_MODE.POSITION_STRUCTURED]: "Delivery Streams",
	[PROJECT_VIEW_MODE.POSITION_SUMMARY]: "Employee summary",
};

export {
	PROJECT_VIEW_MODE,
	PROJECT_VIEW_MODE_TITLE,
};
