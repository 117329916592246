/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const REPORTING_LOCK_ERROR_NOTIFICATION_MESSAGE = (
	"Validation and lock have not been applied due to technical issues. Please try again."
);

export {
	REPORTING_LOCK_ERROR_NOTIFICATION_MESSAGE,
};
