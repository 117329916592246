/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	createApi,
	fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import axios, {
	type AxiosError,
	type AxiosResponse,
	HttpStatusCode,
} from "axios";
import {
	minutesToSeconds,
} from "date-fns";
import constant from "lodash/constant";

import {
	queryParamsSerializerDefault,
} from "utils/api";

const APPLICATION_ORIGIN = "https://time.epam.com";

const API_PREFIX = "/api/";

enum REQUEST_STATUS {
	IDLE = "IDLE",
	LOADING = "LOADING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

const DEFAULT_CACHE_TIME_MINUTES = 5;

const DEFAULT_CACHE_TIME_SECONDS = minutesToSeconds(
	DEFAULT_CACHE_TIME_MINUTES,
);

const onFulfilled = (
	response: AxiosResponse,
): AxiosResponse | Promise<AxiosResponse> => {
	const {
		status,
	} = response;

	if (
		status >= HttpStatusCode.Ok
		&& status < HttpStatusCode.MultipleChoices
	) {
		return response;
	}

	return Promise.reject(response);
};

const onRejected = async (error: AxiosError): Promise<AxiosError> => {
	if (error.response?.status === HttpStatusCode.Unauthorized) {
		window.location.href = `${API_PREFIX}login/epam`;
	}

	return await Promise.reject(error);
};

const api = axios.create({
	baseURL: API_PREFIX,
	paramsSerializer: (queryParams) => {
		return queryParamsSerializerDefault({
			queryParams,
		});
	},
});

api.interceptors.response.use(onFulfilled, onRejected);

enum ReduxToolkitQueryTag {
	LOCKS = "LOCKS",
	OUTLOOK_SYNC = "OUTLOOK_SYNC",
	OVERTIME_REQUESTS = "OVERTIME_REQUESTS",
	PRESENCES = "PRESENCES",
	MARKUPS = "MARKUPS",
}

const reduxToolkitQueryApi = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: "/",
	}),
	keepUnusedDataFor: DEFAULT_CACHE_TIME_SECONDS,
	tagTypes: Object.values(ReduxToolkitQueryTag),
	endpoints: constant({}),
});

export {
	APPLICATION_ORIGIN,
	API_PREFIX,
	REQUEST_STATUS,
	api,
	reduxToolkitQueryApi,
	ReduxToolkitQueryTag,
};
